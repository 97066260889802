import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_JurnalUmumList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.JURNAL_UMUM.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_JurnalUmumDetail = (no_transaksi, callbackSuccess, callbackError) => {
  apiClient(true).get(API.JURNAL_UMUM.GET, {no_transaksi: no_transaksi}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_JurnalUmumSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.JURNAL_UMUM.SAVE, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ListTransaksiTipeForJurnal = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.MASTER.TRANSAKSI_TIPE, params).then(res =>{
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_JurnalUmumList,
  get_JurnalUmumDetail,
  post_JurnalUmumSave,
  get_ListTransaksiTipeForJurnal
}